<template>
  <WsMain class="xs-pa-0">
    <WsCrud
      ref="WsCrud"
      v-bind="$store.state.stone_model.xc_work_overtime"
      :customTableActions="_customTableActions"
      :showFields="showFields"
      @status-ok="$_onStatusOk($event)"
      @status-no="$_onStatusNo($event)"
      :inRowBtnRead="true"
      :updatable="false"
      :deletable="false"
    ></WsCrud>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      showFields: ["admin", "created_at", "start_at", "hour", "status"],
    };
  },
  methods: {
    async $_onStatusOk($event) {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$axios.post(`/xc_work_overtime/${$event.item.id}/approve`);
        this.$refs.WsCrud.updateModelData({
          ...$event.item,
          status: "approved",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    async $_onStatusNo($event) {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$axios.post(
          `/xc_work_overtime/${$event.item.id}/disapprove`
        );
        this.$refs.WsCrud.updateModelData({
          ...$event.item,
          status: "disapproved",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
  },
  computed: {
    _customTableActions() {
      return [
        {
          emit: "status-ok",
          text: "OK",
          displayCheck: (item) => {
            return item.status == "processing" || item.status == "disapproved";
          },
        },
        {
          emit: "status-no",
          text: "No",
          displayCheck: (item) => {
            return item.status == "processing" || item.status == "approved";
          },
        },
      ];
    },
  },
};
</script>