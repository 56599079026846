<template>
  <WsCrud
    ref="WsCrud"
    :label="label"
    v-bind="$props"
    :getUrl="_getUrl"
    :createUrl="_createUrl"
    :dialogCreateSubmitUrl="_dialogCreateSubmitUrl"
    :getDialogUpdateSibmitUrl="$_getDialogUpdateSibmitUrl"
    :getDeleteSubmitUrl="$_getDeleteSubmitUrl"
    :getReadFetchUrl="$_getReadFetchUrl"
    :getReadUrl="$_getReadUrl"
  ></WsCrud>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    $_getReadUrl(item) {
      if (this.getReadUrl) {
        return this.getReadUrl(item);
      } else {
        return `/my/${this.modelName}/${item.id}`;
      }
    },
    $_getReadFetchUrl(item) {
      return `/my/${this.modelName}/${item.id}`;
    },
    $_getDialogUpdateSibmitUrl(item) {
      return `/my/${this.modelName}/${item.id}`;
    },
    $_getDeleteSubmitUrl(item) {
      return `/my/${this.modelName}/${item.id}`;
    },
  },
  computed: {
    _createUrl() {
      if (this.createUrl) {
        return this.createUrl;
      } else {
        return `/my/${this.modelName}/create`;
      }
    },
    _dialogCreateSubmitUrl() {
      return `/my/${this.modelName}`;
    },
    _getUrl() {
      return `/my/${this.modelName}`;
    },
  },
  props: {
    modelName: {},
    filterTabs: {},
    fields: {},
    liveSearching: {},
    showFields: {},
    infiniteScroll: {},
    readable: {},
    creatable: {},
    updatable: {},
    deletable: {},
    inRowBtnRead: {},
    rowClickRead: {},
    pageMode: {},
    dialogCreate: {},
    dialogUpdate: {},
    customTableActions: {},
    createUrl: {},
    getReadUrl: {},
    paramsOnCrud: {},
    label: String,
  },
};
</script>

<style>
</style>