import config from '@/__config';

const model = {
  modelName: "xc_leave_day",
  label: "",
  fields: {
    id: {
      type: "text",
      label: 'ID',
      readonly: true
    },
    admin: {
      type: "belongsTo",
      label: "申請人",
      textKey: "name",
      modelName: config.wsmodule.admin.admin_blur ? 'cmser' : 'admin',
    },
    status: {
      type: 'select',
      label: '狀態',
      readonly: true,
      defauleValue: 1,
      items: [
        {
          text: '簽核中',
          value: 'processing'
        },
        {
          text: '已核准',
          value: 'approved'
        },
        {
          text: '不同意',
          value: 'disapproved'
        },
      ]
    },
    name: {
      type: 'text',
      label: '名稱',
    },
    start_date: {
      type: 'date',
      label: '請假時間'
    },
    days: {
      type: 'number',
      label: '請假長度（天）'
    },
    start_at: {
      type: 'datetime',
      label: '開始時間 (不足一日填寫)'
    },
    hours: {
      type: 'number',
      label: '時數 (不足一日填寫)'
    },
    note: {
      type: 'textarea',
      label: '備註'
    },
    type: {
      type: 'select',
      label: '假別',
      defaultValue: 'annual_leave',
      items: [
        {
          text: '特休',
          value: 'annual_leave',
        },
        {
          text: '病假',
          value: 'sick_leave',
        },
        {
          text: '事假',
          value: 'personal_leave',
        },
        {
          text: '生理假',
          value: 'menstrual_leave',
        },
        {
          text: '喪假',
          value: 'funeral_leave',
        },
      ],
    },
    created_at: {
      type: 'datetime',
      label: '申請時間',
    },
  },
}

export default model