<template>
  <WsMain class="off-day-remain-hours">
    <WsStateForm
      v-model="filter.state"
      :fields="filter.fields"
      @input="$_input"
      class="mb-10"
    />
    <WsCard>
      <WsFlex
        flexDirection="column"
        gap="16px"
      >
        <WsText size="16">可用休假餘額</WsText>
        <WsLoading v-if="loading" />
        <WsFlex
          v-else
          flexDirection="column"
          gap="8px"
          class="off-day-remain-hours-main-content pl-8"
        >
          <WsFlex
            v-for="(item, index) in listData"
            :key="index"
            justifyContent="space-between"
            gap="16px"
          >
            <WsText size="16">{{ item.name }}</WsText>
            <WsText>{{ item.remain_hours }}</WsText>
          </WsFlex>
        </WsFlex>
      </WsFlex>
    </WsCard>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      listData: [],
      loading: false,
      filter: {
        state: {},
        fields: {
          admin: {
            type: "belongsTo",
            label: "人員",
            textKey: "name",
            extendParams: {
              is_active: 1,
            },
            modelName: this.$config.wsmodule.admin.admin_blur
              ? "cmser"
              : "admin",
          },
        },
      },
    };
  },
  methods: {
    $_input($event) {
      this.$_fetchData($event);
    },
    async $_fetchData($event) {
      try {
        this.loading = true;
        this.listData = [];
        let admin_id = $event.admin.id;
        const res = await this.$axios.get(
          `admin/${admin_id}/overview/xc_leave_day`
        );
        if (res?.data) {
          for (const key in res.data) {
            this.listData.push({
              name: res.data[key].name,
              remain_hours: res.data[key].remain_hours,
            });
          }
        }
      } catch (error) {
        alert("發生未知錯誤，請稍後再試");
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.off-day-remain-hours {
  .off-day-remain-hours-main-content {
    border-left: 1px solid var(--primary1l);
  }
}
</style>