import i18n from '@/i18n';

export default {
  my: {
    title: i18n.t('我的好東西'),
    menuItems: [
      'my_board',
      'my_calc',
      'my_calendar',
      'my_punch',
      'my_xc_task',
      'myown_xc_task',
      'my_xc_meeting',
      'my_xc_project',
      'my_off_day',
      'my_off_day_remain_hours',
      'my_wfh',
      'my_substitute',
      'my_substitute_substitute',
      'my_xc_billing_statement',
      'my_xc_work_overtime',
    ],
    modules: [
      'my',
    ]
  },
  working: {
    title: i18n.t('人員管理'),
    menuItems: [
      'admin_calc',
      'xc_task',
      'xc_task_template',
      'xc_task_execute_ref_item',
      'xc_meeting',
      'xc_milestone',
      'xc_project',
      'xc_billing_statement',
      'xc_work_overtime',
      'off_day',
      'off_day_remain_hours',
      'wfh',
      'punch',
      'xc_substitute',
      'xc_process',
    ],
    modules: [
      'calendar',
      'xc_work_type',
      'xc_milestone',
      'admin',
      'hr_recruit',
    ],
  },
  rental: {
    title: i18n.t('空間租借'),
    modules: [
      'rental',
    ]
  },
  activity: {
    title: i18n.t('活動'),
    modules: [
      'activity',
    ]
  },
  course: {
    title: i18n.t('課程'),
    modules: [
      'course',
      'course_order',
    ]
  },
  buxiban: {
    title: '補習班',
    modules: [
      'buxiban',
      'certificate',
    ]
  },
  website: {
    title: i18n.t('網站內容維護'),
    menuItems: [
      'website_content',
      'privacy_terms',
      'website_template',
      'sitemap',
      'robots_txt',
    ],
    modules: [
      'download_info',
      'showcase',
      'ws_blog',
      'faq',
      'news',
    ]
  },
  service: {
    title: i18n.t('服務方案維護'),
    menuItems: [
      'service_plan',
    ],
    modules: [
      'pin_card',
    ]
  },
  shop: {
    title: i18n.t('商城相關維護'),
    modules: [
      'shop',
    ]
  },
  user: {
    title: i18n.t('會員相關維護'),
    modules: [
      'user',
      'socialite',
      'user_subscribe',
      'web_log',
      'user_habit',
      'user_device',
      'otp',
      'app_developer',
      'repair',
      'repair_item',
      'appointment',
      'service_inquery',
      'business_partner',
      'post',
      'report',
    ]
  },
  coin: {
    title: i18n.t('錢幣相關'),
    modules: [
      'coin',
      'top_up',
      'donate',
    ]
  },
  email_system: {
    title: i18n.t("EDM"),
    menuItems: [
      'email_system',
    ],
  },
  financial: {
    title: i18n.t("財務"),
    menuItems: [
    ],
    modules: [
      'xc_financial',
    ]
  },
  business: {
    title: i18n.t("業務"),
    menuItems: [
    ],
    modules: [
      'xc_service_order',
      'xc_rfq',
      'xc_customer',
      'quotation',
      'contact_request',
      'xc_friend',
      'xc_out_resource',
    ]
  },
  cms: {
    title: i18n.t('系統管理'),
    menuItems: [
      'drive',
      'system_setting',
    ],
    modules: [
      'admin',
      'cms_log',
      'application',
      'system_report',
      'setting',
      'app_version',
      'area',
      'acumatica',
      'tag',
      'system_class',
      'locale',
      'system',
      'system_event',
      // 'file_system',
      'subscribe',
      'site_region',
      'edm',
    ]
  },
  service_store: {
    title: i18n.t('服務中心資料維護'),
    modules: [
      'service_store',
    ]
  },
  universe: {
    title: i18n.t('山葵宇宙'),
    modules: [
      'universe'
    ]
  },
  testing: {
    title: i18n.t('Testing'),
    modules: [
      'testing'
    ]
  }
}