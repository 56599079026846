<template>
  <WsCard class="my-off-day-board">
    <WsText
      size="16"
      class="mb-8"
    >我的請假單</WsText>
    <WsModelIndexTabs
      v-model="filter"
      :items="filterTabs"
      class="mb-8"
    />
    <WsModelFetchIndex
      :modelName="modelName"
      :fetchUrl="_fetchUrl"
      v-slot="{modeldatas}"
    >
      <WsModelIndexTable
        :modeldatas="modeldatas"
        v-bind="$props"
        :fields="_fields"
        :showFields="showFields"
        :readable="true"
        :inRowBtnRead="true"
        :showIndexSelects="false"
        @read="$_onRead"
      />
    </WsModelFetchIndex>
    <WsReadDialog
      ref="WsReadDialog"
      :fields="_readFields"
      :modelName="modelName"
      :updatable="false"
      :deletable="false"
      :getReadFetchUrl="$_getReadFetchUrl"
      fetchOnOpen
    />
  </WsCard>
</template>

<script>
export default {
  data() {
    return {
      modelName: this.$store.state.stone_model.xc_leave_day.modelName,
      filter: {},
      filterTabs: [
        {
          text: "簽核中",
          value: 1,
          params: {
            status: 1,
          },
        },
        {
          text: "已核准",
          value: 2,
          params: {
            status: 2,
          },
        },
        {
          text: "不同意",
          value: 3,
          params: {
            status: 3,
          },
        },
        {
          text: "撤回",
          value: 4,
          params: {},
        },
        {
          text: "取消",
          value: 5,
          params: {},
        },
      ],
      showFields: ["start_date", "status"],
    };
  },
  computed: {
    _fields() {
      return this.$store.state.stone_model.xc_leave_day.fields;
    },
    _fetchUrl() {
      return `/my/${this.modelName}`;
    },
    _readFields() {
      return {
        ...this.$o_o.$h.model.getFieldsByKeys(
          this.$store.state.stone_model.xc_leave_day.fields,
          [
            "admin",
            "created_at",
            "type",
            "status",
            "start_date",
            "days",
            "start_at",
            "hours",
            "note",
          ]
        ),
      };
    },
  },
  methods: {
    $_getReadFetchUrl(item) {
      if (item) {
        return `/my/${this.modelName}/${item.id}`;
      }
    },
    $_onRead($event) {
      this.$refs.WsReadDialog.open($event);
    },
  },
};
</script>

<style lang="scss">
.my-off-day-board {
  .ws-model-index__tabs {
    background-color: transparent !important;
    .ws-model-index__tabs__container {
      padding: 0 !important;
    }
  }
}
</style>