import config from '@/__config'

const model = {
  modelName: "xc_work_overtime",
  label: "加班申請",
  fields: {
    id: {
      type: "text",
      label: 'ID',
      readonly: true
    },
    start_at: {
      type: 'datetime',
      label: '加班開始時間'
    },
    hour: {
      type: "number",
      label: "加班時數",
    },
    remark: {
      type: 'textarea',
      label: '備註',
    },
    status: {
      type: 'select',
      label: '狀態',
      readonly: true,
      defauleValue: 1,
      items: [
        {
          text: '簽核中',
          value: 'processing'
        },
        {
          text: '已核准',
          value: 'approved'
        },
        {
          text: '不同意',
          value: 'disapproved'
        },
      ]
    },
    admin: {
      type: "belongsTo",
      label: "CMS使用者",
      textKey: "name",
      modelName: config.wsmodule.admin.admin_blur ? 'cmser' : 'admin',
      readonly: true,
    },
    updated_at: {
      type: "date",
      label: "更新時間",
      readonly: true,
      labelInLocale: true,
    },
    created_at: {
      type: "datetime",
      label: "建立時間",
      readonly: true,
      labelInLocale: true,
    },
  },
  crudSetting: {
    index: {
      showFields: [
        'id',
        'start_at',
        'hour',
        'admin',
        'status',
      ],
      pageMode: false,
      creatable: false,
      updatable: false,
      showFieldsUpdate: [
        "status",
      ]
    },
    index_my: {
      showFields: [
        'id',
        'start_at',
        'hour',
        'admin',
        'status',
      ],
      pageMode: true,
      updatable: false,
      dialogRead: true,
    },
    create_my: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'start_at',
                'hour',
                'remark',
              ],
            },
          ],
        },
      ],
    },
    read: {

    }
  }
}

export default model