export default {
  options: {
    id: true,
    timestamp: true,
  },
  modelName: "xc_project_spec",
  label: "專案規格",
  fields: {
    name: {
      type: 'text',
      label: '名稱',
      rules: 'required'
    },
    content: {
      type: 'editor',
      label: '內容',
      rules: 'required'
    },
  },
  crudSetting: {
    index: {
      showFields: ['id', 'name', 'start_at', 'end_at'],
      creatable: true,
      updatable: true,
      deletable: true,
    }
  }
}