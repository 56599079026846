export default {
  options: {
    id: true,
    timestamp: true,
  },
  modelName: "xc_milestone",
  label: "Milestone",
  fields: {
    name: {
      type: 'text',
      label: '名稱',
      labelInLocale: true,
      rules: 'required'
    },
    content: {
      type: 'textarea',
      label: '內容',
      rules: 'required'
    },
    start_at: {
      type: 'datetime',
      label: '開始日期',
      rules: 'required'
    },
    end_at: {
      type: 'datetime',
      label: '結束日期',
      rules: 'required'
    },
    creator: {
      type: "belongsTo",
      label: "建立人",
      textKey: "name",
      modelName: "admin",
      readonly: true,
      relationPopup: true
    },
  },
  crudSetting: {
    index: {
      showFields: ['id', 'name', 'start_at', 'end_at'],
      creatable: true,
      updatable: true,
      deletable: true,
    }
  }
}