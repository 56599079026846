<template>
  <div>
    <WsUpdate
      label="Header"
      :fields="fields"
      :primary="primary"
      getUrl="/system_setting/web"
      :completeRedirect="false"
      :postEncode="false"
      :value="state"
      @input="$_onInput($event)"
      emitInput
    />
    <WsMain v-if="state?.header?.enable_custom_css">
      <WsCard>
        <WsStateForm
          v-model="state.header"
          :fields="custom_css_fields"
        />
      </WsCard>
    </WsMain>
    <WsMain v-if="state?.header?.enable_custom_header">
      <WsCard>
        <WsStateForm
          v-model="state.header"
          :fields="custom_fields"
        />
      </WsCard>
      <WsCard v-if="state?.header?.custom_header_fields" class="mt-20">
        <WsText size="5">客製化區塊 - 欄位內容</WsText>
        <WsState
          type="form"
          v-model="state.header.custom_header_content"
          :fields="state.header.custom_header_fields"
        />
      </WsCard>
    </WsMain>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fields: {
        header: {
          type: "form",
          fields: {
            is_active: {
              type: 'switch',
              label: this.$t('前台啟用 Header'),
              activeText: 'On',
              inactiveText: 'Off',
              defaultValue: 1,
            },
            type: {
              type: 'select',
              label: 'WsHeaderType',
              items: [
                {
                  text: 'WsHeader001',
                  value: 'WsHeader001',
                },
                {
                  text: 'WsHeader002',
                  value: 'WsHeader002',
                },
                {
                  text: 'WsHeader003',
                  value: 'WsHeader003',
                },
                {
                  text: 'WsHeader004',
                  value: 'WsHeader004',
                },
                {
                  text: 'WsHeader005',
                  value: 'WsHeader005',
                },
                {
                  text: 'WsHeader006',
                  value: 'WsHeader006',
                },
              ],
            },
            stickyHeader: {
              type: "switch",
              label: "Fixed To Top",
              activeText: 'Fixed',
              inactiveText: 'On Fixed',
              defaultValue: true,
            },
            logo: {
              type: "image",
              label: "Logo",
            },
            fixedLogo: {
              type: "image",
              label: "Fixed To Top Logo",
            },
            headerRightButtonTextIcon: {
              type: 'icon-picker',
              label: this.$t('右側按鈕 Icon'),
              col: 6,
            },
            headerRightButtonText: {
              type: "text",
              label: this.$t("右側按鈕文字"),
              col: 6,
            },
            headerRightButtonTo: {
              type: "text",
              label: this.$t("右側按鈕連結 (網站內連結以 / 做開頭)"),
            },
            menuitemDefaultIcon: {
              type: 'icon-picker',
              label: 'menu default icon(icon)',
              col: 3,
            },
            menuitemHoverIcon: {
              type: 'icon-picker',
              label: 'menu hover icon(icon)',
              col: 3,
            },
            menuitemDefaultImg: {
              type: "image",
              label: "menu default icon(image)",
              col: 3,
            },
            menuitemHoverImg: {
              type: "image",
              label: "menu hover icon(image)",
              col: 3,
            },
            menu: {
              type: "list",
              label: this.$t("選單項目"),
              fields: {
                text: {
                  type: "text",
                  label: this.$t("標題"),
                  autofocus: true,
                },
                icon: {
                  type: 'icon-picker',
                  label: 'icon',
                },
                to: {
                  type: "text",
                  label: this.$t("連結 (網站內連結以 / 做開頭)"),
                },
                items: {
                  type: 'list',
                  label: this.$t('子目錄'),
                  textKey: 'text',
                  fields: {
                    text: {
                      type: 'text',
                      label: this.$t('標題'),
                      autofocus: true,
                    },
                    to: {
                      type: 'text',
                      label: this.$t('連結 (網站內連結以 / 做開頭)'),
                    },
                    anchor: {
                      type: 'text',
                      label: this.$t('錨點（同一頁面不能使用相同命名，僅限輸入英文及數字）'),
                    },
                  }
                }
              },
            },
            menuTypesetting: {
              type: 'select',
              label: this.$t('主選單排版方式'),
              items: [
                {
                  text: this.$t('靠左'),
                  value: 'left',
                },
                {
                  text: this.$t('靠右'),
                  value: 'right',
                }
              ]
            },
            subMenuType: {
              type: 'select',
              label: this.$t('子選單樣式'),
              items: [
                {
                  text: this.$t('預設'),
                  value: 'default',
                },
                {
                  text: this.$t('樣式１'),
                  value: 'ws-header-menu-group-type1',
                }
              ],
            },
            enable_custom_css: {
              type: 'switch',
              label: this.$t('啟用自定義樣式'),
              activeText: 'On',
              inactiveText: 'Off',
              defaultValue: false,
            },
            enable_custom_header: {
              type: 'switch',
              label: this.$t('啟用客製化區塊'),
              activeText: 'On',
              inactiveText: 'Off',
              defaultValue: false,
            },
          },
        },
      },
      custom_css_fields: {
        headerBgColor: {
          type: 'color',
          label: 'Header Nav Bar 底色',
        },
        headerRightButtonBorderRadius: {
          type: 'text',
          label: '右側按鈕 BorderRadius (單位必填)',
          col: 6,
        },
        subMenuBorderRadius: {
          type: 'text',
          label: '子選單 BorderRadius (單位必填)',
          col: 6,
        },
        headerRightButtonDefaultBgColor: {
          type: 'color',
          label: '右側按鈕 底色',
          col: 3,
        },
        headerRightButtonDefaultColor: {
          type: 'color',
          label: '右側按鈕 文字顏色',
          col: 3,
        },
        headerRightButtonHoverBgColor: {
          type: 'color',
          label: '右側按鈕 Hover 底色',
          col: 3,
        },
        headerRightButtonHoverColor: {
          type: 'color',
          label: '右側按鈕 Hover 文字顏色',
          col: 3,
        },
        menuColor: {
          type: 'color',
          label: '主選單 文字顏色',
          col: 3,
        },
        menuHoverColor: {
          type: 'color',
          label: '主選單 Hover 文字顏色',
          col: 3,
        },
        subMenuColor: {
          type: 'color',
          label: '子選單 文字顏色',
          col: 3,
        },
        subMenuHoverColor: {
          type: 'color',
          label: '子選單 Hover 文字顏色',
          col: 3,
        },
      },
      custom_fields: {
        custom_header_template: {
          type: 'textarea',
          label: '客製化區塊 - Code - Vue Template'
        },
        custom_header_methods: {
          type: 'textarea',
          label: '客製化區塊 - Code - Vue Methods'
        },
        custom_header_data: {
          type: 'json',
          label: '客製化區塊 - Code - Vue Data'
        },
        custom_header_mounted: {
          type: 'textarea',
          label: '客製化區塊 - Code - Vue Mounted'
        },
        custom_header_style: {
          type: 'textarea',
          label: '客製化區塊 - Code - Style'
        },
        custom_header_fields: {
          type: 'fields',
          label: '客製化區塊 - 欄位',
          labelInLocale: true,
        },
      },
      primary: [
        {
          type: "stateCard",
          floors: [
            {
              fields: ["header"],
            },
          ],
        },
      ],
      state: {},
    }
  },
  methods: {
    $_onInput($event) {
      this.state = {
        ...this.state,
        ...$event,
      }
    },
  },
  mounted() {
    if (this.$config.wsmodule.system_setting?.header?.WsHeaderType === false) {
      delete this.fields.header.fields.type
    }
  },
}
</script>