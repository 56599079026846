<template>
  <div class="ws-state-datetime">
    <DatePicker
      class="ws-state-date-picker"
      :value="_value"
      :format="format"
      type="datetime"
      @input="$_onInput($event)"
      :placeholder="_placeholder"
      :disabled-date="$_disabledDate"
      :disabled-time="$_disabledTime"
      :hour-step="hourStep"
      :minute-step="minuteStep"
      :second-step="secondStep"
    ></DatePicker>
  </div>
</template>

<script>
export default {
  props: {
    clearable: {
      type: Boolean,
      default: true,
    },
    hourStep: {},
    minuteStep: {},
    secondStep: {},
    value: {},
    disableBefore: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "選擇時間",
    },
    format: {
      type: String,
      default: "YYYY-MM-DD HH:mm:ss",
    },
  },
  computed: {
    _value() {
      if (this.value) {
        return new Date(this.value);
      } else {
        return null;
      }
    },
    _placeholder(){
      return `${this.$t(this.placeholder)}`
    }
  },
  methods: {
    $_disabledDate(date) {
      if (!this.disableBefore) {
        return false;
      }
      if (this.$moment(date).isBefore(this.$moment().add(-1, "day"))) {
        return true;
      } else {
        return false;
      }
    },
    $_disabledTime(date) {
      if (!this.disableBefore) {
        return false;
      }
      const _date = this.$moment(date)
        .set("year", this.$moment().year())
        .set("month", this.$moment().month())
        .set("date", this.$moment().date());
      if (this.$moment(_date).isBefore(this.$moment())) {
        return true;
      } else {
        return false;
      }
    },
    $_onInput($event) {
      let _value = null;
      if ($event) {
        _value = this.$moment($event).format(this.format);
      }
      this.$emit("input", _value);
    },
    customFormatter(date) {
      return this.$moment(date).format(this.format);
    },
  },
  components: {},
};
</script>