<template>
  <WsMain>
    <WsModelMyIndex
      :modelName="$store.state.stone_model.xc_leave_day.modelName"
      label="我的請假"
      :fields="_fields"
      :inRowBtnRead="true"
      :updatable="false"
      :showFields="showFields"
    ></WsModelMyIndex>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      showFields: ["admin", "created_at", "days", "start_date", "status"],
    };
  },
  methods: {},
  computed: {
    _fields() {
      return {
        ...this.$o_o.$h.model.getFieldsByKeys(
          this.$store.state.stone_model.xc_leave_day.fields,
          [
            "admin",
            "created_at",
            "type",
            "status",
            "start_date",
            "days",
            "start_at",
            "hours",
            "note",
          ]
        ),
      };
    },
  },
};
</script>

<style>
</style>