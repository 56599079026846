const routes = [
  {
    path: '/my/off_day',
    name: 'my_off_day',
    component: () => import('@/__modules/xc_leave_day/views/MyOffDay/Index.vue'),
    meta: {
      middleware: 'auth'
    }
  },
  {
    path: '/my/off_day_remain_hours',
    name: 'my_off_day_remain_hours',
    component: () => import('@/__modules/xc_leave_day/views/MyOffDay/MyOffDayRemainHours.vue'),
    meta: {
      middleware: 'auth'
    }
  },
  {
    path: '/off_day',
    name: 'off_day',
    component: () => import('@/__modules/xc_leave_day/views/OffDay/Index.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['xc_leave_day-admin'],
    }
  },
  {
    path: '/off_day_remain_hours',
    name: 'off_day_remain_hours',
    component: () => import('@/__modules/xc_leave_day/views/OffDay/OffDayRemainHours.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['xc_leave_day-admin'],
    }
  },
]

export default routes