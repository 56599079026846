import config from '@/__config';
import moment from 'moment';

export default {
  options: {
    id: true,
    timestamp: true,
  },
  modelName: "xc_meeting",
  label: "會議",
  fields: {
    type: {
      type: 'radio',
      label: '會議類型',
      defaultValue: 'external',
      items: [
        { label: '外部會議', value: 'external' },
        { label: '內部會議', value: 'internal' },
      ]
    },
    start_at: {
      type: 'datetime',
      label: '開始時間',
      rules: 'required'
    },
    end_at: {
      type: 'datetime',
      label: '結束時間',
      rules: 'required'
    },
    name: {
      type: 'text',
      label: '會議名稱'
    },
    remark: {
      type: 'textarea',
      label: '會前備註'
    },
    note: {
      type: 'editor',
      label: '會議記錄'
    },
    members: {
      type: 'belongsToMany',
      label: '會議成員',
      modelName: config.wsmodule.admin.admin_blur ? 'cmser' : 'admin',
    },
  },
  crudSetting: {
    index: {
      showFields: ['start_at', 'end_at', 'name'],
      filterTabs: [
        {
          text: "等待中",
          value: 'waiting',
          params: {
            start_at: `${moment().format('YYYY-MM-DD')},null`
          },
        },
        {
          text: "結束",
          value: 'done',
          params: {
            end_at: `null,${moment().format('YYYY-MM-DD')}`
          },
        },
      ]
    },
    index_my: {
      showFields: ['start_at', 'end_at', 'name'],
      pageMode: true,
      creatable: false,
      updatable: false,
      deletable: false,
      dialogRead: true,
      filterTabs: [
        {
          text: "等待中",
          value: 'waiting',
          params: {
            start_at: `${moment().format('YYYY-MM-DD')},null`
          },
        },
        {
          text: "結束",
          value: 'done',
          params: {
            end_at: `null,${moment().format('YYYY-MM-DD')}`
          },
        },
      ]
    },
    read: {
      showFields: [
        "start_at",
        "end_at",
        "name",
        "remark",
        "note",
        "members",
      ]
    }
  }
}