import config from '@/__config';

export default {
  options: {
    id: true,
    timestamp: true,
    tags: true,
  },
  modelName: "xc_task",
  label: "Task",
  fields: {
    xc_task_template: {
      type: 'belongsTo',
      label: 'Task Template',
      textKey: "name",
      modelName: "xc_task_template",
      relationPopup: true,
      hint: '選取會替換空白欄位',
      setStateOnInput(state, $event) {
        if (!$event) {
          return state;
        }
        if (!state.name && $event.name) {
          state.name = $event.name;
        }
        if (!state.hour && $event.hour) {
          state.hour = $event.hour;
        }
        if (!state.content && $event.content) {
          state.content = $event.content;
        }
        if (
          (!state.tags || !state.tags.length) &&
          $event.tags &&
          $event.tags.length
        ) {
          state.tags = $event.tags;
        }
        if (
          (!state.xc_task_execute_ref_items || !state.xc_task_execute_ref_items.length) &&
          $event.xc_task_execute_ref_items &&
          $event.xc_task_execute_ref_items.length
        ) {
          state.xc_task_execute_ref_items = $event.xc_task_execute_ref_items;
        }
        return state;
      },
    },
    name: {
      type: 'text',
      label: 'Task名稱',
      rules: 'required',
      autofocus: true,
      width: null,
      col: 3,
    },
    xc_project: {
      type: 'belongsTo',
      label: '專案',
      textKey: "name",
      modelName: "xc_project",
      relationPopup: true,
      col: 3,
    },
    taker: {
      type: "belongsTo",
      label: "執行人",
      textKey: "name",
      modelName: config.wsmodule.admin.admin_blur ? 'cmser' : 'admin',
      relationPopup: true,
      extendParams: {
        is_active: 1
      },
      col: 3,
    },
    due_date: {
      type: 'date',
      label: '期限',
      col: 3,
    },
    hour: {
      type: 'number',
      label: '預計實行時數',
      col: 3,
    },
    finish_hour: {
      type: 'number',
      label: '實際執行時數',
      col: 3,
      // readonly: true,
    },
    taker_status: {
      type: 'radio',
      label: '執行狀態',
      defaultValue: 0,
      col: 3,
      items: [
        {
          label: '⚫',
          value: 0,
        },
        {
          label: '🟢',
          value: 1,
        },
      ],
      readonly: true,
    },
    start_at: {
      type: 'datetime',
      label: '開始時間',
      col: 3,
    },
    reviewed_at: {
      type: 'datetime',
      label: '覆核時間',
      readonly: true,
    },
    status: {
      type: 'radio',
      label: '狀態',
      defaultValue: 4,
      infoType: 'component',
      component: 'XcTaskStatusLabel',
      items: [
        {
          label: 'Stay',
          value: 1,
        },
        {
          label: 'TR',
          value: 2,
        },
        {
          label: 'Ready',
          value: 3,
        },
        {
          label: 'Open',
          value: 4,
        },
        {
          label: 'Done',
          value: 5,
        },
        {
          label: 'Done-Reject',
          value: 11,
        },
        {
          label: 'Pending',
          value: 12,
        },
        {
          label: 'Close',
          value: 6,
        },
      ]
    },
    // remark: {
    //   type: 'textarea',
    //   label: '備註'
    // },
    time_review_at: {
      type: 'datetime',
      label: '完成時間評估時間',
      readonly: true
    },
    // xc_work_type: {
    //   type: 'belongsTo',
    //   label: '執行類型',
    //   textKey: "name",
    //   modelName: "xc_work_type",
    //   relationPopup: true
    // },
    creator: {
      type: "belongsTo",
      label: "建立人",
      textKey: "name",
      modelName: config.wsmodule.admin.admin_blur ? 'cmser' : 'admin',
      readonly: true,
      extendParams: {
        is_active: 1
      }
    },
    xc_task_execute_ref_items: {
      type: 'belongsToMany',
      label: 'Task 執行參考項目',
      modelName: 'xc_task_execute_ref_item',
      textKey: "name",
      col: 3,
    },
    // is_personal: {
    //   type: 'switch',
    //   label: '私人Task'
    // },
    content: {
      type: 'editor',
      label: '內容',
      maxWidth: 800,
      imageClickable: true,
    }
  },
  crudSetting: {
    index: {
      liveSearching: false,
      pageMode: true,
      showFields: [
        'id',
        'name',
        'status',
        'updated_at',
        'created_at'
      ],
      infiniteScroll: false,
      inRowBtnRead: true,
      creatableScopes: ['xc_task-admin'],
      updatableScopes: ['xc_task-admin'],
      deletableScopes: ['xc_task-admin'],
      filterTabKey: 'status',
      filterTabs: [
        {
          text: '全部',
          value: 'all',
          params: {
          }
        },
        {
          text: 'Stay',
          value: 1,
          params: {
            status: 1
          }
        },
        {
          text: 'TR',
          value: 2,
          params: {
            status: 2
          }
        },
        {
          text: 'Ready',
          value: 3,
          params: {
            status: 3
          }
        },
        {
          text: 'Open',
          value: 4,
          params: {
            status: 4
          }
        },
        {
          text: 'Done',
          value: 5,
          params: {
            status: 5
          }
        },
        {
          text: 'Done-Reject',
          value: 11,
          params: {
            status: 11
          }
        },
        {
          text: 'Pending',
          value: 12,
          params: {
            status: 12
          }
        },
        {
          text: 'Close',
          value: 6,
          params: {
            status: 6
          }
        },
      ],
      filterSelects: {
        taker: {
          type: "belongsTo",
          label: "執行人",
          modelName: config.wsmodule.admin.admin_blur ? 'cmser' : 'admin',
          col: 3,
        }
      }
    },
    create: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'xc_task_template',
                'name',
                'xc_project',
                'taker',
                'due_date',
                'start_at',
                'hour',
                'finish_hour',
                'status',
                'taker_status',
                'content',
                'tags',
              ],
            },
          ],
        },
      ],
    },
    update: {
      submitToIndex: true,
      titleKey: 'name',
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'name',
                'due_date',
                'start_at',
                'taker_status',
                'status',
                'hour',
                'finish_hour',
                'xc_project',
                'taker',
                'content',
                'tags',
              ],
            },
          ],
        },
      ],
    },
    read: {
      leftFields: [
        'name',
        'start_at',
        'remark',
        'time_review_at',
        'reviewed_at',
        'hour',
        'finish_hour',
        'tags',
        'xc_work_type',
        'xc_project',
        'taker',
        'is_personal',
      ],
      rightFields: [
        'xc_task_template',
        'id',
        'status',
        'updated_at',
        'created_at'
      ],
      titleKey: 'name',
    },
  }
}