// import i18n from '@/i18n';
export default {
  my: {},
  admin: {
    calc: true,
    admin_blur: true,
    admin_role: true,
    fields: {
      github: true,
      gitlab: true,
      ssh_public_key: true,
      identity_id: true,
      mobile: true,
      working_email: true,
      personal_email: true,
      identity_card_front: true,
      identity_card_back: true,
      health_card_front: true,
      relate_infos: true,
      email_verified_at: true,
      color: true,
    },
  },
  punch: true,
  tag: true,
  xc_task: true,
  xc_project: true,
  xc_billing_statement: true,
  system: true,
  cms_log: {},
  system_setting: {
    // foooter: {
    // WsFooterType: false
    // }
  },
  file_system: {},
  quotation: {},
  slack: {},
  system_event: {},
  admin_event: {},
  xc_work_overtime: {},
  xc_customer: {},
  xc_service_order: {},
  hr_recruit: {},
  xc_financial: {},
  xc_meeting: {},
  calendar: {},
  xc_rfq: {},
  xc_friend: {},
  xc_out_resource: {},
  gitlab: {},
  xc_milestone: {},
  xc_leave_day: true,
  xc_wfh: true,
  xc_substitute: {},
  xc_project_spec: {},
};
