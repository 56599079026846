<template>
  <WsMain class="my-off-day-remain-hours">
    <WsCard>
      <WsFlex
        flexDirection="column"
        gap="16px"
      >
        <WsText size="16">可用休假餘額</WsText>
        <WsLoading v-if="loading" />
        <WsFlex
          v-else
          flexDirection="column"
          gap="8px"
          class="my-off-day-remain-hours-main-content pl-8"
        >
          <WsFlex
            v-for="(item, index) in listData"
            :key="index"
            justifyContent="space-between"
            gap="16px"
          >
            <WsText size="16">{{ item.name }}</WsText>
            <WsText>{{ item.remain_hours }}</WsText>
          </WsFlex>
        </WsFlex>
      </WsFlex>
    </WsCard>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      listData: [],
      loading: false,
    };
  },
  methods: {
    async $_fetchModel() {
      try {
        this.loading = true;
        const res = await this.$axios.get("my/overview/xc_leave_day");
        if (res?.data) {
          for (const key in res.data) {
            this.listData.push({
              name: res.data[key].name,
              remain_hours: res.data[key].remain_hours,
            });
          }
        }
      } catch (error) {
        alert("發生未知錯誤，請稍後再試");
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.$_fetchModel();
  },
};
</script>

<style lang="scss">
.my-off-day-remain-hours {
  .my-off-day-remain-hours-main-content {
    border-left: 1px solid var(--primary1l);
  }
}
</style>