<template>
  <WsCard class="off-day-board">
    <WsFlex
      flexDirection="column"
      gap="16px"
    >
      <WsText size="16">可用休假餘額</WsText>
      <WsLoading v-if="loading" />
      <WsFlex
        v-else
        flexDirection="column"
        gap="8px"
        class="off-day-board-main-content pl-8"
      >
        <WsFlex
          v-for="(item, index) in listData"
          :key="index"
          justifyContent="space-between"
          gap="16px"
        >
          <WsText size="16">{{ item.name }}</WsText>
          <WsText>{{ item.remain_hours }}</WsText>
        </WsFlex>
      </WsFlex>
      <WsLink
        to="/my/off_day_remain_hours"
        class="py-8"
      >
        <WsFlex
          justifyContent="flex-end"
          alignItems="center"
          gap="8px"
        >
          <WsText
            size="14"
            :color="$color.gray2d"
          >查看所有假別餘額</WsText>
          <WsIcon
            name="icon-ws-outline-arrow-right"
            :color="$color.gray2d"
          />
        </WsFlex>
      </WsLink>
    </WsFlex>
  </WsCard>
</template>

<script>
export default {
  data() {
    return {
      listData: [],
      loading: false,
    };
  },
  methods: {
    async $_fetchModel() {
      try {
        this.loading = true;
        const res = await this.$axios.get("my/overview/xc_leave_day");
        if (res?.data) {
          for (const key in res.data) {
            if (
              key == "annual_leave" ||
              key == "sick_leave" ||
              key == "personal_leave"
            ) {
              this.listData.push({
                name: res.data[key].name,
                remain_hours: res.data[key].remain_hours,
              });
            }
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.$_fetchModel();
  },
};
</script>

<style lang="scss">
.off-day-board {
  .off-day-board-main-content {
    border-left: 1px solid var(--primary1l);
  }
}
</style>