export default {
  my_off_day: {
    icon: 'icon-md-near-me',
    title: '我的 請假',
    link: '/my/off_day',
  },
  my_off_day_remain_hours: {
    icon: 'icon-md-near-me',
    title: '我的 假別餘額',
    link: '/my/off_day_remain_hours',
  },
  off_day: {
    icon: 'icon-md-near-me',
    title: '請假',
    link: '/off_day',
    scopes: ['xc_leave_day-admin']
  },
  off_day_remain_hours: {
    icon: 'icon-md-near-me',
    title: '假別餘額',
    link: '/off_day_remain_hours',
    scopes: ['xc_leave_day-admin']
  },
};