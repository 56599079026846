<template>
  <WsCard>
    <WsText
      size="16"
      class="mb-8"
    >待我簽核的加班單</WsText>
    <WsModelFetchIndex
      modelName="xc_leave_day"
      v-slot="{modeldatas}"
    >
      <WsModelIndexTable
        :modeldatas="modeldatas"
        v-bind="$props"
        :fields="_fields"
        :showFields="showFields"
        :readable="true"
        :inRowBtnRead="true"
      />
    </WsModelFetchIndex>
  </WsCard>
</template>

<script>
export default {
  data() {
    return {
      showFields: ["name", "start_date", "status"],
    };
  },
  computed: {
    _fields() {
      return this.$store.state.stone_model.xc_leave_day.fields;
    },
  },
};
</script>
