import H_Router from '@/__stone/helpers/router'

const routes = [
  ...H_Router.getCrudTemplateRoutes({
    routes: [
      'index_my',
      'create_my',
    ],
    modelName: 'xc_work_overtime',
    middleware: 'auth',
  }),
  {
    path: '/xc_work_overtime',
    name: 'xc_work_overtime',
    component: () => import('@/__modules/xc_work_overtime/views/WorkOvertime/Index.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['xc_work_overtime-admin'],
    }
  },
]
export default routes